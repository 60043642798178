import styled from 'styled-components';

export const BlockContainer = styled.div`
  margin-bottom: 5rem;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 80%;
  }
`;

export const BlockHeader = styled.h1`
  max-width: 90%;
  margin: auto;
  padding-left: 1em;
  padding-top: 1em;
  border-top: ${({ mainPage }) =>
    mainPage ? 'none' : '1px solid rgba(105, 105, 105, 0.5)'};
`;

export const BlockDescription = styled.p`
  box-sizing: border-box;
  padding: 50px;
  max-width: 90%;
  background: ${({ mainPage }) => (mainPage ? 'rgba(0,0,0,0.5)' : '#fff')};
  margin: auto;
  text-align: justify;
  border-top: ${({ mainPage }) => (mainPage ? 'none' : '2px solid #0a51a0')};
  font-size: 1.3em;
  overflow-y: auto;
  color: ${({ mainPage }) => (mainPage ? '#fbf7f0' : '#555555')};

  @media screen and (max-width: 768px) {
    text-align: left;
    line-height: 1.6rem;
  }
`;

export const VeterinaProizvodi = styled.div`
  display: flex;
  background: ${({ mainPage }) => (mainPage ? 'rgba(0,0,0,0.5)' : '#ffffff')};
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  margin: auto;
  padding-bottom: 60px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;
