import React, { useEffect } from 'react';
import {
  BlockDescription,
  ColumnContainer,
  AgrarProizvodi,
  BlockHeader,
  BlockContainer,
  Image,
  ListContainer,
  ListItem,
  List,
  CloseIcon,
  Icon,
} from './InfoElements';
import Divider from 'rsuite/lib/Divider';
import Veterinary from '../Veterinary';

const ColumnInformationBlock = (props) => {
  const Description = () => {
    if (props.title === 'veterina') {
      return <Veterinary />;
    } else if (props.title === 'agrar') {
      return (
        <BlockContainer>
          <BlockHeader>Agrar</BlockHeader>
          <BlockDescription>
            U sklopu poslovnog centra Bosna-Vet nalazi se i poljoprivredna
            apoteka u kojoj možete pronaći:
          </BlockDescription>
          <ListContainer>
            <List>
              <ListItem>sadni material</ListItem>
              <ListItem>poljoprivredni alat</ListItem>
              <ListItem>zaštitnu opremu</ListItem>
              <ListItem>đubriva</ListItem>
              <ListItem>opremu i hranu za kućne ljubimce…</ListItem>
            </List>
            <br /> <br />
            Iskusni agronom će Vam u svakom trenutku pomoći sa neophodnim
            savjetima.
            <br /> <br />
            <Divider />
            <strong style={{ display: 'block', textAlign: 'center' }}>
              Posjetite nas u Zenici, na adresi Goraždanska 36a, ili nas
              kontaktirajte pozivom na broj 032 200 690.
            </strong>
          </ListContainer>
          <AgrarProizvodi>
            <Image
              src="https://bosnavet.ba/wp-content/uploads/2019/10/agrar-2.jpg"
              alt="Agrar 2"
            />
            <Image
              src="https://bosnavet.ba/wp-content/uploads/2019/10/agrar-1.jpg"
              alt="Agrar 1"
            />
          </AgrarProizvodi>
        </BlockContainer>
      );
    }
  };

  useEffect(() => {
    if (props.show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'overlay';
    }

    return () => (document.body.style.overflow = 'overlay');
  }, [props.show]);

  return (
    <ColumnContainer show={props.show}>
      <Icon onClick={() => props.toggle()}>
        <CloseIcon color={'#555555'} />
      </Icon>
      <Description />
    </ColumnContainer>
  );
};

export default ColumnInformationBlock;
