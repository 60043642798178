import React, { useState } from 'react';
import { isIE } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column,
  TextWrapper,
  Headline,
  Header,
  Subtitle,
  ColumnWrapper,
  ColumnButton,
  WaveContainer,
  WavePattern,
  InfoImage,
} from './InfoElements';
import BackgroundVeterina from '../../assets/images/veterina.jpg';
import BackgroundAgrar from '../../assets/images/agrar.jpg';
import BackgroundDijagnostika from '../../assets/images/dijagnostika.jpg';
import ColumnInformationBlock from './ColumnInformation';
import FadeInViewBox from '../FadeInViewBox';

const InfoSpace = () => {
  const [veterinaColumn, setVeterinaColumn] = useState(false);
  const [agrarColumn, setAgrarColumn] = useState(false);
  const history = useHistory();

  const toggleVeterinaColumn = () => {
    setVeterinaColumn((prevColumn) => !prevColumn);
  };
  const toggleAgrarColumn = () => {
    setAgrarColumn((prevColumn) => !prevColumn);
  };

  const pathStyle = { stroke: 'none', fill: '#fbf7f0', overflow: 'hidden' };

  return (
    <InfoContainer>
      <InfoImage />
      {!isIE && (
        <WaveContainer>
          <WavePattern
            viewBox="0 0 500 150"
            preserveAspectRatio="xMinYMin meet"
          >
            <path
              d="M-9.31,27.13 C64.61,57.72 271.49,-49.98 512.69,33.05 L500.00,0.00 L0.00,0.00 Z"
              style={pathStyle}
            ></path>
          </WavePattern>
        </WaveContainer>
      )}
      <InfoWrapper>
        <InfoRow>
          <ColumnWrapper>
            <FadeInViewBox>
              <Column show={veterinaColumn} image={BackgroundVeterina}>
                <TextWrapper>
                  <Headline>Veterina</Headline>
                  <Header>
                    Profesionalan odnos prema kupcima i konstantno nastojanje
                    unapređenja veterinarskih usluga svrstavaju Bosna-Vet među
                    lidere u veterinarskom snabdijevanju na području Bosne i
                    Hercegovine.
                  </Header>
                  <Subtitle>
                    <ColumnButton onClick={toggleVeterinaColumn}>
                      Saznaj Više
                    </ColumnButton>
                  </Subtitle>
                </TextWrapper>
              </Column>
            </FadeInViewBox>
            <ColumnInformationBlock
              title="veterina"
              show={veterinaColumn}
              toggle={toggleVeterinaColumn}
            />
          </ColumnWrapper>
          <ColumnWrapper>
            <FadeInViewBox>
              <Column image={BackgroundAgrar}>
                <TextWrapper>
                  <Headline>Agrar</Headline>
                  <Header>
                    U sklopu poslovnog centra Bosna-Vet nalazi se i
                    poljoprivredna apoteka u kojoj možete pronaći: sadni
                    material poljoprivredni alat zaštitnu opremu đubriva opremu
                    i hranu za kućne ljubimce.
                  </Header>
                  <Subtitle>
                    <ColumnButton onClick={toggleAgrarColumn}>
                      Saznaj Više
                    </ColumnButton>
                  </Subtitle>
                </TextWrapper>
              </Column>
            </FadeInViewBox>
            <ColumnInformationBlock
              title="agrar"
              show={agrarColumn}
              toggle={toggleAgrarColumn}
            />
          </ColumnWrapper>
          <ColumnWrapper>
            <FadeInViewBox>
              <Column image={BackgroundDijagnostika}>
                <TextWrapper>
                  <Headline>Dijagnostika i laboratorij</Headline>
                  <Header>
                    Poseban značaj pridajemo iznalaženju optimalnih rješenja za
                    laboratorije čije su aktivnosti usmjerene ka zaštiti
                    zdravlja ljudi i životinja, sigurnosti hrane i zaštiti
                    okoline.
                  </Header>
                  <Subtitle>
                    <ColumnButton
                      compo
                      onClick={() =>
                        history.push('/dijagnostika-i-laboratorij')
                      }
                    >
                      Saznaj Više
                    </ColumnButton>
                  </Subtitle>
                </TextWrapper>
              </Column>
            </FadeInViewBox>
          </ColumnWrapper>
        </InfoRow>
      </InfoWrapper>
    </InfoContainer>
  );
};

export default InfoSpace;
