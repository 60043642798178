import React from 'react';
import 'intersection-observer';
import { Bounce } from 'react-awesome-reveal';

const FadeInViewBox = ({ children }) => {
  return (
    <Bounce cascade triggerOnce>
      {children}
    </Bounce>
  );
};

export default FadeInViewBox;
