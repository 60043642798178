import React from 'react';
import {
  BlockDescription,
  VeterinaProizvodi,
  BlockHeader,
  BlockContainer,
} from './style';

import UlazDDD from '../../assets/images/veterina_proizvodi/Ulaz-DDD.png';
import UlazDSS from '../../assets/images/veterina_proizvodi/Ulaz-DSS.jpg';
import UlazVetLijekovi from '../../assets/images/veterina_proizvodi/Ulaz-Vet-lijekovi-III.jpg';
import UlazInstrumenti from '../../assets/images/veterina_proizvodi/Ulaz-veterinarski-instrumenti-i-oprema.jpg';
import ItemContainer from '../InfoSpace/ItemContainer';

const veterinaProizvodiList = [
  {
    img: UlazVetLijekovi,
    title: 'Veterinarski lijekovi i vakcine',
    src: 'veterinarski-lijekovi',
  },
  {
    img: UlazDDD,
    title: 'Sredstva za dezinfekciju, dezinsekciju i deratizaciju',
    src: 'ddd',
  },
  {
    img: UlazDSS,
    title: 'Duboko smrznuto sjeme bikova',
    src: 'dss',
  },
  {
    img: UlazInstrumenti,
    title: 'Veterinarski instrumenti i oprema',
    src: 'veterinarski-instrumenti-i-oprema',
  },
];

const Veterinary = ({ mainPage }) => {
  return (
    <BlockContainer>
      <BlockHeader mainPage={mainPage}>Veterina</BlockHeader>
      <BlockDescription mainPage={mainPage}>
        Snabdijevanje je organizovano u jednakim vremenskim intervalima, po
        potrebi i interventno, sa namjenski opremljenim vozilima.
        <br /> <br />
        U sklopu poslovnog objekta Bosna-Vet -a nalazi se skladište veleprodaje
        koje sa ugrađenim najsavremenijim hladnim komorama i pratećom opremom
        zadovoljava sve međunarodne standarde u oblasti prometa farmaceutskih i
        dijagnostičkih proizvoda.
        <br /> <br />
        Kao potvrdu navedenog koristimo činjenicu da su vrhunski svjetski
        proizvođači veterinarskih lijekova i vakcina, sredstava za dezinfekciju,
        dezinsekciju i deratizaciju, duboko smrznutog sjemena bikova i
        veterinarskih instrumenata i opreme, u Bosna-Vet -u pronašli pouzdanog i
        ozbiljnog partnera i ukazali nam svoje povjerenje.
        <br /> <br />
      </BlockDescription>
      <VeterinaProizvodi mainPage={mainPage}>
        {veterinaProizvodiList.map((item, index) => (
          <ItemContainer
            mainPage={mainPage}
            img={item.img}
            title={item.title}
            href={item.src}
            key={index}
          />
        ))}
      </VeterinaProizvodi>
    </BlockContainer>
  );
};

export default Veterinary;
