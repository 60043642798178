import React from 'react';
import { ItemImage, SmallHeader, NavLink } from './InfoElements';

const imageStyle = {};

const ItemContainer = ({ title, img, href, mainPage }) => {
  return (
    <NavLink to={href ? href : '#'}>
      <ItemImage style={imageStyle} src={img} alt={title} />
      <SmallHeader mainPage={mainPage}>{title}</SmallHeader>
    </NavLink>
  );
};

export default ItemContainer;
