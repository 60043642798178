import styled from 'styled-components';
import img from '../../assets/images/dogs.jpg';
import { FaTimes } from 'react-icons/fa';
import { NavLink as RouterNavLink } from 'react-router-dom';

export const InfoContainer = styled.section`
  color: #d9e4dd;
  position: relative;
  padding-top: 100px;
  background-color: ${({ light }) => (light ? '#f9f9f9' : '#010606')};

  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;

export const InfoImage = styled.div`
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100vh;
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${img});
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${img});
  // background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

export const InfoWrapper = styled.div`
  display: grid;
  position: relative;
  z-index: 3;
  min-height: 1000px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  padding: 0 24px;
  justify-content: center;
`;

export const InfoRow = styled.div`
  /* display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }*/
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ColumnWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;

export const BlockContainer = styled.div`
  margin-bottom: 5rem;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 80%;
  }
`;

export const BlockHeader = styled.h1`
  max-width: 90%;
  margin: auto;
  padding-left: 1em;
  padding-top: 1em;
  border-top: 1px solid rgba(105, 105, 105, 0.5);
`;

export const ColumnInformation = styled.div`
  opacity: ${({ show }) => (show ? 1 : 0)};
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};

  position: absolute;
  background: #d9e4dd;
  background-color: #fbf7f0;
  color: #555555;
  width: 1000px;
  height: 500px;
  z-index: 3;
  overflow-y: scroll;
  transition: all 0.5s ease-in-out;
`;

export const ColumnContainer = styled(ColumnInformation)`
  position: fixed;
  padding: 0;
  margin-top: 80px;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  z-index: 999;
  padding-bottom: 4rem;
`;

export const VetColumn = styled(ColumnInformation)``;
export const AgColumn = styled(ColumnInformation)``;
export const DLColumn = styled(ColumnInformation)``;

export const Column = styled.div`
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${({ image }) => image});
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${({ image }) => image});
  transition: 2s all ease-in-out;
  background-size: cover;
  background-position: center;
  margin-bottom: 15px;
  padding: 0 15px;
  width: 500px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  @media screen and (max-width: 480px) {
    width: 400px;
  }

  @media screen and (max-width: 375px) {
    width: 330px;
  }

  @media screen and (max-width: 320px) {
    width: 280px;
  }
`;

export const ColumnNext = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  max-width: 90%;
  min-height: 100%;
  padding-top: 0;
`;

export const Headline = styled.p`
  color: #d9e4dd;
  font-size: 36px;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;

  @media screen and (max-width: 425px) {
    font-size: 24px;
    padding-top: 10px;
  }
`;

export const Header = styled.h1`
  margin: 30% auto 0.2em auto;
  font-size: 1.4em;
  letter-spacing: 0.1rem;
  line-height: 1.5;
  font-weight: 600;
  color: ${({ light }) => (light ? '#f7f8fa' : '#fbf7f0')};
  max-width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 10px;
  text-align: center;

  @media screen and (max-width: 480px) {
    //font-size: 32px;
  }

  @media screen and (max-width: 375px) {
    width: 250px;
    font-size: 16px;
    text-align: center;
  }
`;

export const Subtitle = styled.p`
  display: flex;
  justify-content: center;
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  margin: auto;
  color: ${({ dark }) => (dark ? '#010606' : '#fff')};
`;

export const ColumnButton = styled.button`
  display: block;
  border-radius: 5px;
  background: #0a51a0;
  border: none;
  border-left: 2px solid #021b2c;
  border-bottom: 2px solid #021b2c;
  white-space: nowrap;
  padding: 10px;
  color: #d9e4dd;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-decoration: none;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #094c96;
    border-left: 2px solid #0a51a0;
    border-bottom: 2px solid #0a51a0;
    box-shadow: inset 5px 5px 5px #094990, inset -5px -5px 5px #0b59b0;
    color: hsl(142deg 1% 52%) !important;
  }
`;

export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;

export const WaveContainer = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: 15%;
  vertical-align: middle;
  overflow: hidden;
  z-index: 3;
`;

export const WavePattern = styled.svg`
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
`;

export const BlockDescription = styled.p`
  box-sizing: border-box;
  padding: 50px;
  max-width: 90%;
  background: #ffffff;
  margin: auto;
  text-align: left;
  border-top: 2px solid #0a51a0;
  font-size: 1.2em;
  overflow-y: auto;
`;

export const ListContainer = styled.div`
  box-sizing: border-box;
  padding: 0 50px 50px;
  max-width: 90%;
  background: #ffffff;
  margin: auto;
  text-align: left;
  font-size: 1.2em;
`;

export const VeterinaProizvodi = styled.div`
  display: flex;
  background: #ffffff;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  margin: auto;
  padding-bottom: 60px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const AgrarProizvodi = styled(VeterinaProizvodi)`
  justify-content: center;
  gap: 2rem;
`;

export const Image = styled.img`
  max-width: 500px;
  width: inherit;
  border: 3px #0a51a0 solid;
  outline: none;
  margin-bottom: 10px;
`;

export const List = styled.ul`
  @media screen and (max-width: 480px) {
    padding-left: 1rem;
  }
`;

export const ListItem = styled.li`
  color: #0a51a0;
  padding: 0.2rem;
`;

export const SmallHeader = styled.h5`
  color: ${({ mainPage }) => (mainPage ? '#fbf7f0' : '#0a51a0')};
  padding-top: 0.5rem;
  max-width: 250px;
  text-align: center;
  font-weight: 600;

  &:hover {
    color: hsl(100, 53%, 80%);
  }
`;

export const ItemImage = styled.img`
  display: block;
  text-align: center;
  max-height: 150px;
  margin: auto;

  @media screen and (max-width: 1500px) {
    max-height: 100px;
  }
  @media screen and (max-width: 1024px) {
    max-height: 200px;
  }
`;

export const NavLink = styled(RouterNavLink)`
  margin: 20px auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
`;

export const CloseIcon = styled(FaTimes)`
  color: ${({ color }) => (color !== undefined ? color : '#fff')};
`;

export const Icon = styled.div`
  position: absolute;
  transform: translate(-85%, 30%);
  font-size: 1.8rem;
  top: 0;
  right: 0;
  background: transparent;
  cursor: pointer;
  outline: none;
`;
